import React from "react";
import JoinTeam from "../components/Applications";
import JoinLanding from "../components/JoinLanding";
function JoinPage() {
  return (
    <>  
      <JoinLanding />
      <JoinTeam />
    </>
  );
}

export default JoinPage;
